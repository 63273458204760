import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UsersService {

    constructor(
        public http: HttpClient,
        public router: Router,
    ) { }

    public mobileVersionUpdate (request) {
      return this.http.post<any>(environment.base_url + "forceUpdate", request)
    }

    public getUsers(data) {
        return this.http.get<any>(environment.node_base_url + `ch-users/ch-admin-users/adminUsers?page=${data.page}&size=${data.size}&sort=${data.sort+","+data.sortType}&userType=${data.userType}&search=${data.search}`,{ observe: "response" })
    }

    public addUser(data) {
        return this.http.post<any>(environment.base_url + "ch-admin-users/add",data)
    }

    public deleteUser(ids){
        return this.http.post<any>(environment.base_url + "ch-user/admin/user/delete?userIds="+ids,null)
    }

    public getRemainingCount(id){
        return this.http.get<any>(environment.node_base_url + "ch-users/ch-admin-users/userCount?id="+id)
    }

    public getEmergencyContact(id) {
        return this.http.get<any>(environment.base_url + "ch-user/emergency-contact/"+id)
    }


    public getTrips(id) {
         return this.http.get<any>(environment.base_url + "ch-trip/byAdmin?userId="+id)
    }

    public getAllGroups(){
        return this.http.get<any>(environment.base_url + "ch-user/admin/groups")
    }

    public getGroupUsers(groupID){
        return this.http.get<any>(environment.base_url + `ch-user/group/users?groupId=${groupID}&search=`)
    }


    public getDatabaseGroupUsers(data){
        return this.http.get<any>(environment.base_url + `ch-user/group/users?page=${data.page}&size=${data.size}&sort=${data.sort+","+data.sortType}&groupId=${data.groupId}&chUserType=${data.userType}&search=${data.search}`,{ observe: "response" })
    }

    public getGroupsUser(data){
        return this.http.get<any>(environment.base_url + `ch-user/group/users?groupId=${data.id}&search=${data.search}&chUserType=${data.userType}`)
    }

    public addNewGroup(data){
        return this.http.post<any>(environment.base_url + "ch-user/add/group",data)
    }

    public updateGroup(data){
        return this.http.post<any>(environment.base_url + "ch-user/update/group",data)
    }

    public deleteGroup(id){
        return this.http.get<any>(environment.base_url + `ch-user/group/delete?id=${id}`)
    }

    public removeUserFromGroup(data){
        return this.http.post<any>(environment.base_url + `ch-user/group/remove/users?groupId=${data.group_id}&userId=${data.user_id}`,null)
    }

    public updatefolderName(data){
        return this.http.post<any>(environment.base_url + `ch-groups/update?id=${data.id}&name=${data.groupName}`,null)
    }

    public deleteFolder(data){
        return this.http.post<any>(environment.base_url + `folder/delete`,data)
    }

    public getNotifications(){
        return this.http.get<any>(environment.base_url + `ch-user/notification?userType=ADMIN`)
    }

    public deleteWebToken(){
        return this.http.get<any>(environment.base_url + `ch-user/delete/web-token`)
    }

    public uploadCSVUsers(data){
        return this.http.post<any>(environment.base_url + `ch-admin-users/upload-csv-file`,data)
    }

    public updateUserInfo(data){
        return this.http.post<any>(environment.base_url + `ch-user/updateByAdmin?firstName=${data.firstName}&lastName=${data.lastName}&organization=${data.organization}&phone=${data.phoneNumber}&position=${data.position}&userId=${data.userId}`,data)
    }

    public getUserDetails(userID) {
        return this.http.get<any>(environment.base_url + `ch-users/${userID}`)
    }

    public getCurrentUserInfo(){
        return this.http.get<any>(environment.base_url + "ch-user/me")
    }
}
