import { Router } from '@angular/router';
// import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpEvent, HttpEventType, HttpResponse, HttpRequest } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TripService {

    constructor(
        public http: HttpClient,
        public router: Router,

    ) { }

    public createTripStepOne(data) {
        return this.http.post<any>(environment.node_base_url + "ch-users/ch-trips/add/step1",data)
    }


    public getTripPaymentData(data) {
      console.log('data', data)
      return this.http.get<any>(environment.node_base_url + "ch-users/ch-stripe/payment/data",data)
  }

    public updateTripDetails(data){
        return this.http.post<any>(environment.base_url + "ch-trips/trip/update",data)
    }

    public createTripStepTwo(data) {
        return this.http.post<any>(environment.base_url + "ch-trips/add/step2",data)
    }

  public proccedPayment(data) {
    return this.http.post<any>(environment.node_base_url + "ch-users/ch-stripe/payment-link", data)
  }

  public applyPromoCode(data) {
    return this.http.get<any>(environment.base_url + "ch-stripe/promo/" + data.promoCode)
  }


    public createTripStepThree(data) {
      return this.http.post<any>(environment.base_url + "ch-trips/add/step3",data)
  }

  public addtoGroup(data) {
    return this.http.post<any>(environment.base_url + "ch-user/update/group",data)
}

    public getTavelItinerary(data) {
        return this.http.post<any>(environment.base_url + `ch-trips/get/travel/itinerary?tripId=${data.tripID}&page=${data.page}&size=${data.size}&search=${data.search}`,{ observe: "response" })
    }
    public addNewTravel(data){
        return this.http.post<any>(environment.node_base_url + "ch-users/ch-trips/add/travel/itinerary",data)
    }

    public getTripAttendees(data){
        if(data.sort){
            return this.http.post<any>(environment.node_base_url + `ch-users/ch-trips/attendees/getByTrip?page=${data.page}&size=${data.size}&sort=${data.sort+","+data.sortType}&tripId=${data.tripId}&tripUserType=${data.userType}&teamId=${data.teamId}&search=${data.search}`,{ observe: "response" });
        }else{
            return this.http.post<any>(environment.node_base_url + `ch-users/ch-trips/attendees/getByTrip?page=${data.page}&size=${data.size}&tripId=${data.tripId}&tripUserType=${data.userType}&teamId=${data.teamId}&search=${data.search}`,{ observe: "response" });
        }
    }

    public getTripAttendeesNew(data){
        return this.http.post<any>(environment.base_url+ `ch-trips/attendees/getByDefaultTeam?tripId=${data.tripId}&teamId=${data.teamId}`,{ observe: "response" });
    }


    public getItinerary(id) {
        return this.http.post<any>(environment.node_base_url + `ch-users/ch-trips/get/all/itinerary?tripId=${id}`,{})
    }

    public getItineraryHtml(data) {
      return this.http.post<any>(environment.node_base_url + `ch-users/generatePdf`,data)
      // return this.http.post<any>(environment.node_base_url + `ch-users/ch-trips/get/all/itinerary/html?tripId=${id}`,{})
  }

    public addItineraryEvent(data) {
        return this.http.post<any>(environment.node_base_url + `ch-users/ch-trips/add/event/itinerary`,data)
    }

    public updateItineraryTime(data) {
        return this.http.put<any>(environment.base_url + `ch-trips/update/event/itinerary`,data)
    }

    public addTraelMember(data) {
        return this.http.post<any>(environment.base_url + `ch-trips/travel/member/add`,data)
    }

    public getTravelMember(data){
        return this.http.post<any>(environment.node_base_url + `ch-users/ch-trips/travel/member/get?travelId=${data.travelId}`,{ observe: "response" });
    }

    public snetTripNotification(tripID){
        return this.http.post<any>(environment.base_url + `ch-trip/send/email/`+tripID,null);
    }

    public getTripListing(data){
        return this.http.get<any>(environment.base_url + `ch-trip/admin/byStatusIn?statuses=${data.status}&size=${data.size}&page=${data.page}&search=${data.search}`,{ observe: "response" })
    }

    public getTripDetails(id) {
        return this.http.get<any>(environment.node_base_url + 'ch-users/ch-trip/'+id)
    }

    public getTravel(id) {
        return this.http.get<any>(environment.node_base_url + 'ch-users/ch-trips/get/itinerary/'+id)
    }

    public getTripTeams(id){
        return this.http.get<any>(environment.base_url + 'ch-trip/teams/'+id)
    }


    // public getUsers(data) {
    //     return this.http.get<any>(environment.node_base_url + `ch-users/ch-admin-users/adminUsers?page=${data.page}&size=${data.size}&sort=${data.sort+","+data.sortType}&userType=${data.userType}&search=${data.search}`,{ observe: "response" })
    // }

    // https://java-apperone-backend.agiletechnologies.in/api/ch-trip/team/8396851?page=0&search=patel&size=10

    public getTeamMembers(data){
        return this.http.get<any>(environment.base_url + `ch-trip/team/${data.teamID}?page=${data.page}&size=${data.size}&sort=${data.sort+","+data.sortType}&search=${data.search}`,{ observe: "response" })

        // return this.http.get<any>(environment.base_url + 'ch-trip/team/'+teamID)
    }

    public getTravelLegs(id) {
        return this.http.get<any>(environment.node_base_url + 'ch-users/ch-travel-legs/getByTravel/'+id)
    }

    public getAdminGroups() {
        return this.http.get<any>(environment.base_url + 'ch-user/admin/groups')
    }

    public getAllFolders(){
        return this.http.get<any>(environment.node_base_url + 'ch-users/admin/folders')
    }

    public addnewFolder(data){
        return this.http.post<any>(environment.base_url + `admin/folder/create`,data)
    }

    public updateFolder(data){
        return this.http.put<any>(environment.base_url + `folder/update/${data.id}/${data.folderName}`,null)
    }

    public updateTripFolder(data){
        return this.http.post<any>(environment.base_url + `add/trip/folder`,data)
    }

    public movetoArchive(ids){
        return this.http.get<any>(environment.base_url + `ch-trip/archive/?id=${ids}`)
    }

    public deleteTrips(ids){
        return this.http.get<any>(environment.base_url + `ch-trip/delete/?id=${ids}`)
    }

    public addTeam(data) {
         return this.http.post<any>(environment.base_url + `ch-trip/team/add`,data)
    }

    public addtripAttendeeIds(data){
        return this.http.post<any>(environment.base_url + `ch-trip/team/member/add`,data)
    }

    public updateTeam(data){
        return this.http.post<any>(environment.base_url + `ch-trip/team/edit`,data)
    }

    public getTripPhotos(tripId){
        return this.http.get<any>(environment.base_url + `ch-trip/photos/${tripId}`)
    }

    public deleteTravelItenery(ids) {
        return this.http.get<any>(environment.base_url + `ch-itinerary/delete?id=${ids}`)
    }

    public uploadTripPhotos(data){
        return this.http.post<any>(environment.base_url + `ch-trip-photo/upload`,data)
    }

    public getNotificationUsers(data){
        return this.http.get<any>(environment.base_url + `ch-admin-users/getNotificationUsers?userType=${data.userType}&tripId=${data.tripId}`);
    }

    public sendCustomNotification(data){
        return this.http.post<any>(environment.base_url + `ch-admin-users/sendCustomNotification`,data);
    }

    public getTrip(data) {
        return this.http.get<any>(environment.base_url + `ch-trip/super-admin/getTrips?licenceKey=${data.licenceKey}&userId=${data.userId}&userType=${data.userType}`);
    }

    public getEmergencyContact(id) {
        return this.http.get<any>(environment.base_url + `ch-user/emergency-contact/${id}`);
    }

    //  sprint 6
    public addAttendeesmail(data) {
        // return this.http.post<any>(environment.base_url + `ch-trip/send/email/users?tripId=${data.tripId}&emailRequest=${(data.emailRequest)}`,{});
        return this.http.post<any>(environment.base_url + `ch-trip/send/email/users`,data); //sprint 6

    }

    public deletePhoto(data){
        return this.http.post<any>(environment.base_url + `ch-trip/photos/delete?id=${data.id}&tripId=${data.tripId}`,null);
    }

    public getAdminAsLeader(id){
        return this.http.get<any>(environment.base_url + `ch-trip/team/getAdminAsLeader?teamId=${id}`);
    }

    public setAdminAsLeader(id){
        return this.http.post<any>(environment.base_url + `ch-trip/team/addAdminAsLeader?teamId=${id}`,null);
    }

    public removeAdminAsLeader(id){
        return this.http.post<any>(environment.base_url + `ch-trip/team/removeAdminAsLeader?teamId=${id}`,null);
    }

    //remove attendece from trip
    public removeUserFromTrip(data){
        return this.http.post<any>(environment.base_url + `ch-trips/delete/user?tripId=${data.tripID}&userIds=${data.IDs}`,null);
    }

    //approve reject photo
    public approveRejectPhoto(data){
        return this.http.post<any>(environment.base_url + `ch-trip-photo/approve/${data.id}?status=${data.status}`,null);
    }

    public getTripProvider(tripID) {
        return this.http.get<any>(environment.node_base_url + `ch-users/ch-trip/provider?tripId=${tripID}`)
    }

    //for attendees component CSV file upload flow


    uploadCSVFile(file: File, tripId: any, userType?: string): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tripId', tripId);
        formData.append('type', userType);

        const headers = new HttpHeaders({
          'security-token': 'Bearer YOUR_TOKEN_HERE'
        });

        const req = new HttpRequest('POST',
          environment.node_base_url + `ch-users/uploadCsvforAttendees`,
          formData, {
            headers: headers,
            reportProgress: true,
          });

        return this.http.request(req).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                const blob: Blob = event.body;
                // Process the blob data here or return it directly
                return blob;
              }
            return event;
          })
        );
      }

    uploadCSVFileForTeam(file: File, tripId: any, teamId: string): Observable<any> {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('tripId', tripId);
      formData.append('teamId', teamId);

      const headers = new HttpHeaders({
        'security-token': 'Bearer YOUR_TOKEN_HERE'
      });

      const req = new HttpRequest('POST',
        environment.node_base_url + `ch-users/uploadCsvforTeam`,
        formData, {
          headers: headers,
          reportProgress: true,
        });

      return this.http.request(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
              const blob: Blob = event.body;
              // Process the blob data here or return it directly
              return blob;
            }
          return event;
        })
      );
    }


      uploadCSVFileItinerary(file: File, data:any): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('travelId', data.travelId);
        formData.append('addedMembers', data.addedMembers);
        formData.append('totalMembers', data.totalMembers);

        const headers = new HttpHeaders({
          'security-token': 'Bearer YOUR_TOKEN_HERE'
        });

        const req = new HttpRequest('POST',
          environment.node_base_url + `ch-users/uploadCsvforItnary`,
          formData, {
            headers: headers,
            reportProgress: true,
          });

        return this.http.request(req).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                const blob: Blob = event.body;
                // Process the blob data here or return it directly
                return blob;
              }
            return event;
          })
        );
      }

      uploadCSVFileDatabase(file: File, type: string): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', type);

        // formData.append('travelId', travelId);

        const headers = new HttpHeaders({
          'security-token': 'Bearer YOUR_TOKEN_HERE'
        });

        const req = new HttpRequest('POST',
          environment.node_base_url + `ch-users/uploadCsvforDatabase`,
          formData, {
            headers: headers,
            reportProgress: true,
          });

        return this.http.request(req).pipe(
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                const blob: Blob = event.body;
                // Process the blob data here or return it directly
                return blob;
              }
            return event;
          })
        );
      }

      public updateTripStatus(data){
        return this.http.put<any>(environment.base_url + `ch-trips/trip/update/${data.tripID}?chTripStatus=${data.chTripStatus}`,null);
    }

    addTrip(tripCode,id){
        return this.http.post<any>(environment.base_url + `ch-trips/attendees/addTrip?code=${tripCode}&tripAttendeesId=${id}&type=ADMIN`,null)
    }

    deleteTravelLeg(data){
        return this.http.post<any>(environment.base_url + `ch-travel-legs/remove`,data)
    }

    public deleteTeam(id){
        return this.http.delete<any>(environment.base_url + `ch-teams/${id}`)
    }

    createBulkList(createObj) {
      return this.http.post<any>(environment.base_url + 'ch-lists/lists/create/bulk', createObj);
    }

    getListByUUID(data) {
      return this.http.get<any>(environment.node_base_url + `ch-users/ch-list/list/${data.uuid}?page=${data.page}&size=${data.size}&search=${data.search}${data.sort ? `&sort=${data.sort+","+data.sortType}` : ''}`, {observe: 'response'});
    }

    deleteMainList(id: string) {
      return this.http.delete<any>(environment.base_url + `ch-list/lists/${id}`);
    }

    deleteSubList(id: string) {
      return this.http.delete<any>(environment.base_url + `ch-list/lists/list?ids=${id}`);
    }

    addAttendeesToList(addObject) {
      return this.http.post<any>(environment.base_url + `ch-list/lists/list/addAttendees`, addObject);
    }

    getAllAttendeesByListIds(queryParams: string) {
      return this.http.get<any>(environment.base_url + `ch-list/lists/list/allAttendees?${queryParams}`);
    }

    getAttendeesByListId(data) {
      return this.http.get<any>(environment.node_base_url + `ch-users/ch-list/lists/list/getAllAttendees/${data.id}?page=${data.page}&size=${data.size}&search=${data.search}${data.sort ? `&sort=${data.sort+","+data.sortType}` : ''}`, {observe: 'response'});
    }

    removeAttendeesFromList(removeObj: {attendeesIds: string[], id: string}) {
      return this.http.post<any>(environment.base_url + 'ch-list/lists/list/removeAttendees', removeObj);
    }

    updateSubListName(updateObj: {id: number, name: string}) {
      return this.http.put<any>(environment.base_url + 'ch-list/lists/list/update/name', updateObj);
    }

    updateMainList(updateObj) {
      return this.http.put<any>(environment.base_url + 'ch-list/lists/update', updateObj);
    }

    setTeamLeader(createObj) {
      return this.http.post<any>(environment.base_url + 'ch-list/lists/add/teamLeader', createObj);
    }

    getRemainingAttendeesByListType(data) {
      if (!data.tripId || !data.listType) return;
      return this.http.get<any>(environment.base_url + `ch-lists/lists/remainingAttendees?listType=${data.listType}&tripId=${data.tripId}&page=${data.page}&size=${data.size}&search=${data.search}${data.sort ? `&sort=${data.sort+","+data.sortType}` : ''}`, {observe: 'response'});
    }
}
