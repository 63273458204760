import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { numberToWordsPipe } from '../pipes/numberToWords.pipe';
import { NumberDirective } from '../pipes/numbers-only.directive';
import { NoSpaceMaxLengthDirective } from '../pipes/no-space-max-length.directive';
import { NumberDecimalDirective } from '../pipes/number-decimal.directive';
import { AlphabetSpaceDirective } from '../pipes/alphabet-space.directive';
import { NoSpaceMaxLengthAlphanumericDirective } from '../pipes/numbers-text-only.directive';

export const options: Partial<IConfig> | (() => Partial<IConfig>) ={};

@NgModule({
  declarations: [
    numberToWordsPipe,
    NumberDirective,
    NoSpaceMaxLengthDirective,
    AlphabetSpaceDirective,
    NumberDecimalDirective,
    NoSpaceMaxLengthAlphanumericDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    numberToWordsPipe,
    NumberDirective,
    NoSpaceMaxLengthAlphanumericDirective,
    NoSpaceMaxLengthDirective,
    AlphabetSpaceDirective,
    NumberDecimalDirective
  ]
})
export class SharedModule {}

