import { AfterViewInit, Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { parsePhoneNumber } from 'libphonenumber-js';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AdminService } from 'src/app/services/admin.service';
import { PhoneNumberService } from 'src/app/services/phonenumber-format';
import { SearchService } from 'src/app/services/search.service';
import { UsersService } from 'src/app/services/users.service';
import { storedUserData } from 'src/app/shared/helpers/const-helper';
import { ModelReferenceService } from 'src/app/shared/model-reference.service';
import { filter } from 'rxjs/operators';  // Import filter operator
import { SidebarService } from 'src/app/shared/sidebar/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit,AfterViewInit  {
  data: any;
  modalAccount: BsModalRef;
  modalDropdown: BsModalRef;
  isHidden = false;
  profileSumitted: boolean = false;
  isEditing: boolean = false;
  editAdminForm: FormGroup;
  fileToUpload: any;
  filesSelectName = '';
  public selectedFile: any;
  selectedFileType: any = '';
  public url: any;
  modalNotifications: BsModalRef;
  AdminNotifications: any = [];
  selectedImage: any = 'assets/images/profile-placeholder-image.svg';
  RemainingUsers: number = 0 || Number(localStorage.getItem('maxUser'));
  adminDetail: any = {
    imageUrl: 'assets/images/profile-placeholder-image.svg',
  };
  allowRouteForSearch = [
    '/admin-management/list-administrator',
    '/incharge-admin-management/list-administrator',
    '/all-user-management/all-user-listing',
    '/trips',
    '/trips/archive',
    '/trips/itinerary',
    '/trips/list',
    '/trips/list/attendees',
    '/database',
    '/database/group',
    '/trips/attendees',
    '/promoCode',
    '/settings',
    '/admin-management/view-administrator',
    '/promoCode/view-promo-code',
    '/admin-management/billing-tab-list'
  ];

  constructor(
    public router: Router,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private userserive: UsersService,
    private modalRefService: ModelReferenceService,
    private phoneNumberService: PhoneNumberService,
    private adminService: AdminService,
    private userService: UsersService,
    private toastr: ToastrService,
    private searchService: SearchService,
    private sidebarService: SidebarService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  get checkCondition() {
    if(this.router.url.includes('/admin-management/view-administrator')) {
      return this.allowRouteForSearch.some(route => this.router.url.startsWith(route));
    }else{
      return this.allowRouteForSearch.includes(this.router.url.split('?')[0]);
    }
  }

  get checkRemainingCondition() {
    const currentUrl = this.router.url.split('?')[0];
    return (
      currentUrl === '/database' ||
      currentUrl === '/database/group' ||
      this.router.url.includes('/trips/attendees')
    );
  }
  get calenderView() {
    return localStorage.getItem('calenderView') == 'true' ? true : false
  }
  getRemainingUsers() {
    const user = storedUserData();
    if (user && user.chUserDTO && user.chUserDTO.id) {
      this.userService.getRemainingCount(user.chUserDTO.id).subscribe((response) => {
        this.RemainingUsers = response.data ? response.data.maxUser : 0;
        this.sidebarService.setUserRemainingCount(this.RemainingUsers)
        localStorage.setItem("maxUser",this.RemainingUsers ? this.RemainingUsers.toString() :'')
      });
    } else {
      console.warn("User data is not available");
    }
  }

  imgErrorHandler(event) {
    event.target.src = 'assets/images/profile-placeholder-image.svg';
  }

  // openDropdown() {
  //   this.isHidden = !this.isHidden
  // }

  openDropdown(dropdown: TemplateRef<any>) {
    this.modalDropdown = this.modalService.show(
      dropdown,
      Object.assign({}, { class: 'custom-dropdown-modal' })
    )
    this.modalRefService.setParentModalRef(this.modalDropdown);
  }

  openFromProfile(account: TemplateRef<any>) {
    this.modalDropdown?.hide();
    this.userserive.getCurrentUserInfo().subscribe((response) => {
      this.data = { ...this.adminDetail, ...response.data };

      this.adminDetail = { ...this.data };
      this.selectedImage = this.adminDetail.imageUrl;
    });

    this.isHidden = false;
    this.modalAccount = this.modalService.show(
      account,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setParentModalRef(this.modalAccount);
  }
  logout() {
    this.modalDropdown?.hide()
    this.userserive.deleteWebToken().subscribe((res) => {
      console.log('token delete');
    });
    this.isHidden = false;
    this.searchService.changeSearchValue("");
    sessionStorage.getItem('userData')
      ? sessionStorage.removeItem('userData')
      : localStorage.removeItem('cuserData');
    localStorage.removeItem('trip_');
    this.router.navigate(['login']);
  }

  edit() {
    this.isEditing = true;
  }

  checkSession() {
    return sessionStorage.getItem('userData') ? false : true;
  }

  ngOnInit(): void {
    // this.getRemainingUsers();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd) // Filter out NavigationEnd events
      )
      .subscribe(() => {
        if (this.checkRemainingCondition) {
          this.sidebarService.getUserCount().subscribe((count) => {
            this.RemainingUsers = count;
          });
          this.getRemainingUsers();
        }
      });

    // this.userserive.getCurrentUserInfo().subscribe((response) => {
    //   this.data = { ...this.adminDetail, ...response.data };

    //   this.adminDetail = { ...this.data };
    //   this.selectedImage = this.adminDetail.imageUrl;
    // });
  }

  notificationsModal(notifications: TemplateRef<any>) {
    this.getAllNotifications();
    this.modalNotifications = this.modalService.show(
      notifications,
      Object.assign({}, { class: 'modal-lg modal-dialog-centered' })
    );
    this.modalRefService.setChildModalRef(this.modalNotifications);
  }

  /* get admin notifications */
  getAllNotifications() {
    this.ngxLoader.start();
    this.userserive.getNotifications().subscribe((res) => {
      this.ngxLoader.stop();
      // this.AdminNotifications = res.data;
      this.AdminNotifications = res.data.map((notification) => {
        notification.timestamp = moment(notification.createdDate).unix();
        return notification;
      });

      this.AdminNotifications = this.AdminNotifications.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });

      let lastItenaryDate = '';
      let finalItenaryList = [];
      this.AdminNotifications.map((itinerary, index) => {
        if (lastItenaryDate == moment(itinerary.createdDate).format('D')) {
          finalItenaryList[finalItenaryList.length - 1].push(itinerary);
        } else {
          finalItenaryList[finalItenaryList.length] = [itinerary];
          lastItenaryDate = moment(itinerary.createdDate).format('D');
        }
      });
      this.AdminNotifications = finalItenaryList;

      if (this.AdminNotifications.length > 0) {
        var todayDate = moment().format('L');
        var dateOne = moment(this.AdminNotifications[0][0].createdDate).format(
          'L'
        );
        if (todayDate == dateOne) {
          this.AdminNotifications[0][0].istoday = true;
        }
        if (this.AdminNotifications.length > 1) {
          var todayDate = moment().subtract(1, 'days').format('L');
          var dateOne = moment(
            this.AdminNotifications[1][0].createdDate
          ).format('L');
          if (todayDate == dateOne) {
            this.AdminNotifications[1][0].isyesterday = true;
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    this.editAdminForm = this.fb.group({
      email: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      organization: ['', Validators.required],
      phone: ['', Validators.required],
      position: ['', Validators.required],
      countryCode: [''],
    });

    this.userserive.getCurrentUserInfo().subscribe((response) => {
      response.data.phone = response.data.phone.includes('+')
        ? response.data.phone
        : '+1' + response.data.phone;
      const { countryCallingCode, nationalNumber } = parsePhoneNumber(
        response.data?.phone
      );
      response.data.countryCode = countryCallingCode ? countryCallingCode : '1';
      response.data.phone = nationalNumber;
      this.data = { ...this.adminDetail, ...response.data };
      this.adminDetail = { ...this.data };
      this.selectedImage = this.adminDetail.imageUrl;
      this.patchForm();
    });
  }

  onFileSelect(event: any) {
    // const selectedFile = event.target.files[0];
    // this.imageCompress.uploadFile().then(({ image, orientation }) => {
    //   // this.imageCompress
    //   //   .compressFile(image, orientation, 50, 50)
    //   //   .then((result) => {
    //   //     var formData = new FormData();
    //       const file = this.DataURIToBlob(image);
    //       this.createImageFromBlob(file);

    //       this.profileImageFile = file;
    //     // });
    // });

    if (event.target.files && event.target.files.length > 0) {
      this.filesSelectName = event.target.files[0].name;
      this.selectedFile = <File>event.target.files;
      var reader = new FileReader();
      this.selectedFileType = event.target.files[0]?.type;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.url = this.b64toBlob(reader.result);
      };
    }
  }

  b64toBlob(dataURI) {
    this.selectedImage = dataURI;
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    this.fileToUpload = new Blob([ab], { type: this.selectedFileType });
    return this.fileToUpload;
  }

  patchForm() {
    if (this.adminDetail) {
      // console.log('this.adminDetail: ', this.adminDetail);
      this.data = this.phoneNumberService.displayNumbers(
        this.adminDetail.phone
      );
      // console.log('this.data: ', this.data);
      this.editAdminForm.patchValue({
        email: this.adminDetail.email,
        firstName: this.adminDetail.firstName,
        lastName: this.adminDetail.lastName,
        organization: this.adminDetail.organization,
        phone: this.adminDetail.phone,
        position: this.adminDetail.position,
        countryCode: this.adminDetail.countryCode,
      });
    }
  }

  updateProfile() {
    this.profileSumitted = true;
    this.isEditing = false;
    if (this.editAdminForm.invalid) {
      return;
    }
    if (this.editAdminForm.valid) {
      // this.editAdminForm.value.phone = "+"+this.selectedCountryCode+"-"+this.editAdminForm.value.phone;
      // this.editAdminForm.value.phone = "+91-8352805272"

      var clean = ('' + this.editAdminForm.value.phone).replace(/\D/g, '');
      var phoneResult = this.phoneNumberService.formatPhoneNumber(
        clean,
        this.editAdminForm.value.countryCode
      );
      console.log('cleaned: phoneResult ', phoneResult);

      var formData = new FormData();
      if (this.fileToUpload) {
        // formData.append('image', this.profileImageFile);
        formData.append('image', this.fileToUpload, this.filesSelectName);
      }
      formData.append(
        'data',
        new Blob(
          [
            JSON.stringify({
              ...this.editAdminForm.value,
              phone: phoneResult,
              id: this.adminDetail.id,
              maxUser: null,
            }),
          ],
          { type: 'application/json' }
        )
      );
      this.ngxLoader.start();
      this.adminService.updateAdmin(formData).subscribe(
        (response) => {
          if (response.code && response.code == 400) {
            this.toastr.error(response.message, 'Error');
            this.ngxLoader.stop();
            return;
          }
          this.fileToUpload = null;
          this.filesSelectName = null;

          response.data.phone = this.phoneNumberService.displayNumbers(
            response.data.phone
          ).phoneNumber;
          this.editAdminForm.patchValue(response.data);
          this.adminDetail = { ...this.adminDetail, ...response.data };
          let userData = storedUserData();
          userData.chUserDTO = this.adminDetail;
          sessionStorage.getItem('userData')
            ? sessionStorage.setItem('userData', JSON.stringify(userData))
            : localStorage.setItem('cuserData', JSON.stringify(userData));
          if (!this.adminDetail.imageUrl) {
            this.adminDetail.imageUrl =
              'assets/images/profile-placeholder-image.svg';
          }

          this.profileSumitted = false;
          this.userserive.getCurrentUserInfo().subscribe((response) => {
            this.data = { ...this.adminDetail, ...response.data };
            this.adminDetail = { ...this.data };
            this.ngxLoader.stop();
            // this.selectedImage = this.adminDetail.imageUrl
          });
          this.toastr.success(response.message, 'Success');
        },
        (error) => {
          console.log('error: ', error);
          if (error.error.statusCodes == 403) {
            // this.toastr.error(error.error.message, 'Error');
            this.modalAccount.hide();
            sessionStorage.getItem('userData')
              ? sessionStorage.removeItem('userData')
              : localStorage.removeItem('cuserData');
            this.router.navigate(['/login']);
          } else {
            this.toastr.error('Unable to update profile', 'Error');
          }
        }
      );
    }
  }
}
