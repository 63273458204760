import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoSpaceMaxLengthAlphanumeric]'
})
export class NoSpaceMaxLengthAlphanumericDirective {
  @Input() appNoSpaceMaxLengthAlphanumeric!: number; // Maximum length of the input

  constructor(
    private el: ElementRef, 
    private renderer: Renderer2,
    private ngControl: NgControl // Used for form validation
  ) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value;

    // Remove non-alphanumeric characters and leading spaces
    let transformedValue = inputValue.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphanumeric characters
    transformedValue = transformedValue.replace(/^\s+/, ''); // Prevent starting with a space

    // Limit the input length
    transformedValue = transformedValue.slice(0, this.appNoSpaceMaxLengthAlphanumeric);

    // Set the transformed value back to the input
    this.renderer.setProperty(inputElement, 'value', transformedValue);

    // Update form control value programmatically
    if (this.ngControl.control) {
      this.ngControl.control.setValue(transformedValue, { emitEvent: false });
    }
  }
}
